import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "rockbet",
  casinoId: 34,
  GA_TRACKING:'G-QR5QJ5NDME',

  rivalChatGroupName: "Rockbet",
  prettyName: "Rockbet",
  contactEmail: "support@rockbet.com",
  docsEmail: "documents@rockbet.email",


  publicPromotions: [
    {id: 'TRUEFORTUNE_1', name: "200", state: "eligible", details: "welcome", imgPath: "assets/brands/rockbet/promo.png"},
    {id: 'TRUEFORTUNE_2', name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/rockbet/promo.png"},
    {id: 'TRUEFORTUNE_3', name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/rockbet/promo.png"},
  ],


  //SEO
  metaDescription: "Join Casino Rockbet, earn free spins, real cash bonus, no deposit bonuses and exclusive welcome offers. Fast betting and secure transactions even in crypto. Play rockbet online casino slots to earn free money today!",
  logoName: "casino-rockbet-online-casino-logo.png",
  logoAltText: "Rockbet Casino online logo with a green dice icon and sleek font catering to slots table games and high-roller betting bonuses."
};


